<template>
<div>

  <b-modal id="modal-revoke-invitation" centered hide-footer title-class="h3">
    <template v-slot:modal-title>
      Are you sure?
    </template>
    <div class="d-block">
      <p v-if="adminData !== null">Are you sure you want to revoke the invitation for <strong>{{adminData.email}}</strong>?</p>
    </div>
    <div class="d-block">
      <div class="d-flex justify-content-between">
        <button class="btn btn-block text-dark btn-lg btn-link" v-on:click="$bvModal.hide('modal-revoke-invitation')">Cancel</button>
        <button class="btn btn-block btn-lg btn-danger ml-1 mr-lg-3" v-on:click="revokeInvitationConfirm()">Yes, revoke</button>
      </div>
    </div>
  </b-modal>

  <b-modal id="modal-remove-admin" centered hide-footer title-class="h3">
    <template v-slot:modal-title>
      Are you sure?
    </template>
    <div class="d-block">
      <p v-if="adminData !== null">Are you sure you want to remove <br><strong>{{adminData.name}}</strong><br> from the system?</p>
    </div>
    <div class="d-block">
      <div class="d-flex justify-content-between">
        <button class="btn btn-block text-dark btn-lg btn-link" v-on:click="$bvModal.hide('modal-remove-admin')">Cancel</button>
        <button class="btn btn-block btn-lg btn-danger ml-1 mr-lg-3" v-on:click="removeAdminConfirm()">Yes, remove</button>
      </div>
    </div>
  </b-modal>


  <div class="container-fluid wrapper-medium mt-3">
    <div class="row mb-3">
      <div class="col-12">
        <b-link class="btn btn-link btn-sm pl-0" to="/dashboard"><span class="icon icon-th-large mr-2 pl-0"></span>Dashboard</b-link>
        <h2 class="anim-load1 mt-2">Admin users management</h2>
      </div>
    </div>
  </div>

  <div class="container-fluid wrapper-medium">

    <div class="row mb-5 mt-3">
      <div class="col-12 col-md-6">
        <div class="card mb-3">
          <div class="card-body">
            <h4 class="mb-3">Administrators</h4>
            <div class="accordion" v-for="(admin, i) in adminProfiles" v-bind:key="i">
              <h6 class="accordion-title" v-b-toggle="'admin-' + i">
                <span>{{admin.name}}</span> 
                <span class="icon-up-open"></span>
              </h6>
              <b-collapse :id="'admin-'+i" class="mt-2 accordion-content">
                <button v-if="admin.name !== $store.state.user.email" class="btn btn-danger" v-on:click="removeAdmin(admin)">Remove this Administrator</button>
                <p v-if="admin.name === $store.state.user.email" class="mb-0">This is your account</p>
              </b-collapse>
            </div>
          </div>
        </div>
        <div class="card" v-if="pendingAdminProfiles.length">
          <div class="card-body">
            <h4 class="mb-3"><i class="icon icon-clock mr-2 pl-0"></i>Pending Invites</h4>
            <div class="accordion" v-for="(pendingAdmin, i) in pendingAdminProfiles" v-bind:key="i">
              <h6 class="accordion-title" v-b-toggle="'pendingAdmin-' + i">
                <span>{{pendingAdmin.email}}</span> 
                <span class="icon-up-open"></span>
              </h6>
              <b-collapse :id="'pendingAdmin-'+i" class="mt-2 accordion-content">
                <p>
                  <strong>Admin account </strong> 
                  invite has been sent to {{pendingAdmin.email}}</p>
                <button class="btn btn-danger" v-on:click="revokeInvitation(pendingAdmin)">Revoke invitation</button>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <h4 class="mb-3">Invite new Admin user</h4>
            <p>Enter new Administrator email address.</p>
            <ValidationObserver ref="formInvite" class="border-bottom mb-2 pb-2">
              <b-form @submit.prevent="submitNewAdmin" class="mb-3">
                <div class="form-group" ref="email">
                  <label for="emailInput">Email address</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required|email" name="Email address">
                    <input v-model="form.email" type="text" placeholder="" name="emailInput" class="form-control" v-bind:class="classes"> 
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                
                <button type="submit" class="btn btn-warning" >Send invite</button>
              </b-form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</div>

</template>

<script>

export default {
  name: 'AdminManager',
  data () {
    return {
      form: {
        email: null
      },
      adminData: null,
      adminProfiles: [],
      pendingAdminProfiles: [],
    }
  },
  computed: {
  },
  async created() {
    this.$store.state.appSection = 'appUser'
    await this.getAdmins();
    await this.getInvites();
    console.log('Data loaded')
    this.$store.state.loadingProgress = false
  },
  methods: {

    getAdmins() {
      this.$store.state.loadingProgress = true
      return this.$http({
        method: 'get', 
        url: this.$api.AdminAllGet,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          console.log(response)
          this.adminProfiles = response.data
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Something went wrong", false);
        })
        .finally(() => {
          this.$store.state.loadingProgress = false
        })
    },

    getInvites() {
      this.$store.state.loadingProgress = true
      return this.$http({
        method: 'get', 
        url: this.$api.AdminInvitesGet,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          console.log(response)
          this.pendingAdminProfiles = response.data
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Something went wrong", false);
        })
        .finally(() => {
          this.$store.state.loadingProgress = false
        })
    },

    submitNewAdmin () {
      this.$refs.formInvite.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.formInvite.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.formInvite.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          this.$store.state.loadingProgress = true
          this.$http({
            method: 'post', 
            url: this.$api.AdminInvite,
            headers: {'Authorization': 'bearer ' + this.$store.state.jwt },
            params: {
              email: this.form.email
            }
          })
            .then((response) => {
              console.log(response)
              this.adminProfiles = response.data
              this.form.email = null
              this.alertMsgShow('Invite sent!', true)
              this.$nextTick(() => this.$refs.formInvite.reset())
            })
            .catch((error) => {
              console.error('Error', error)
              this.alertMsgShow("Something went wrong", false);
            })
            .finally(() => {
              this.getAdmins();
              this.getInvites();
              this.$store.state.loadingProgress = false
            })
        }
      });
    },

    revokeInvitation(data) {
      console.log(data)
      this.adminData = data
      this.$bvModal.show('modal-revoke-invitation')
    },

    revokeInvitationConfirm () {
      this.$bvModal.hide('modal-revoke-invitation')
      this.$store.state.loadingProgress = true
      this.$http({
        method: 'post', 
        url: this.$api.AdminInvitesRevoke,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt },
        params: {
          email: this.adminData.email
        }
      })
        .then((response) => {
          console.log(response)
          this.alertMsgShow('Invite revoked!', true);
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Something went wrong", false);
        })
        .finally(() => {
          this.getAdmins()
          this.getInvites()
          this.adminData = null
          this.$store.state.loadingProgress = false
        })

    },

    removeAdmin(data) {
      this.adminData = data
      this.$bvModal.show('modal-remove-admin')
    },

    removeAdminConfirm() {
      this.$store.state.loadingProgress = true
      this.$bvModal.hide('modal-remove-admin')
      this.$http({
        method: 'post', 
        url: this.$api.AdminDelete,
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt },
        params: {
          adminId: this.adminData.id
        }
      })
        .then((response) => {
          console.log(response)
          this.alertMsgShow('Admin removed!', true);
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow("Something went wrong", false);
        })
        .finally(() => {
          this.getAdmins()
          this.getInvites()
          this.adminData = null
          this.$store.state.loadingProgress = false
        })
    },
  }
}
</script>
